import { createSlice } from '@reduxjs/toolkit'

import { defaultParameters } from '../config'

function setMosaicSettings (state, action) {
  const { payload } = action
  return { ...state, ...payload }
}

function toggleDialogOpen (state) {
  const { dialogOpen } = state

  return { ...state, dialogOpen: !dialogOpen }

}

const auth = createSlice({
  name: 'auth',
  initialState: {...defaultParameters, dialogOpen: false},
  reducers: {
    setMosaicSettings,
    toggleDialogOpen,
  }
})

export default auth
