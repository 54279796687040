import { createSlice } from '@reduxjs/toolkit'

import { defaultAuth } from '../config'

function authRequest (state, action) {
  return state
}

function authSuccess (state, action) {
  const { payload } = action
  return { ...state, ...payload }
}

function signOut (state, action) {
  return {}
}

const auth = createSlice({
  name: 'auth',
  initialState: defaultAuth,
  reducers: {
    authRequest,
    authSuccess,
    signOut
  }
})

export default auth
