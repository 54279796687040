import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
// import { combineEpics, createEpicMiddleware } from 'redux-observable'
// import { firebaseReducer } from 'react-redux-firebase'
// import { firestoreReducer } from 'redux-firestore'
import LogRocket from 'logrocket'

import authDuck from '../redux/authDuck'
import mapDuck from '../redux/mapDuck'
import mosaicDuck from '../redux/mosaicDuck'

// const firestore = firebase.firestore()
// const rootEpic = (...args) =>
//   combineEpics(catalogEpic, downloadEpic, mapEpic)(...args, firestore)

// const epicMiddleware = createEpicMiddleware()

const reducer = combineReducers({
  auth: authDuck.reducer,
  map: mapDuck.reducer,
  mosaic: mosaicDuck.reducer,
})

const store = configureStore({
  reducer: reducer,
  middleware: [LogRocket.reduxMiddleware()],
  devTools: true
})

// epicMiddleware.run(rootEpic)

// window.firestore = firestore
window.store = store

export default store
