// @flow

import React, { type Node } from 'react'

import LoadingContainer from '../containers/LoadingContainer'

function LoadingRoute (): Node {
  return <LoadingContainer />
}

export default LoadingRoute
