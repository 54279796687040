// @flow

import React, { type Node } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import Loading from '../components/Loading'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function LoadingContainer (): Node {
  const classes = useStyles()

  return (
    <Container maxWidth='sm' className={classes.container}>
      <Loading />
    </Container>
  )
}

export default LoadingContainer
