// @flow

import React, { type Node } from 'react'

import NavigationContainer from './navigation/NavigationContainer'
import art from './components/art'

console.log(art, "font-family:monospace; color:#005da6;")

function App(): Node {
  return (
    <NavigationContainer />
  )
}

export default App
