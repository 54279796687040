const debug = false

// Mapbox
export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

// Sentry
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN

// Title
export const title = process.env.REACT_APP_TITLE
export const description = process.env.REACT_APP_DESCRIPTION

// Mosaic map values
export const defaultViewport = {
  latitude: 56.396804053287994,
  longitude: -116.88929557800289,
  zoom: 18
}

// Mosaic urls
export const defaultMosaicUrl =
  'https://c-core-secure.s3.ca-central-1.amazonaws.com/osprey-integrity/mosaic-json/aoi1_mosaic.json'
export const defaultExtension = 'png'
export const defaultScale = 1
export const defaultPixelSelection = 'first'
export const defaultResamplingMethod = 'nearest'

export const defaultTilerUrl = !debug
  ? process.env.REACT_APP_DEFAULT_TILE_URL
  : 'http://localhost:8080/secure/mosaic/{z}/{x}/{y}'

export const defaultTileJsonUrl = !debug
  ? process.env.REACT_APP_DEFAULT_TILE_JSON_URL
  : 'http://localhost:8080/secure/mosaic/tilejson.json'

export const defaultScales = [1, 2, 3]

export const defaultRescale = '0,255-0,255-0,255'
export const defaultRescales = [defaultRescale]

export const defaultTilerUrls = [defaultTilerUrl]

export const defaultTileJsonUrls = [defaultTileJsonUrl]

export const defaultMosaicUrls = [
  'https://c-core-secure.s3.ca-central-1.amazonaws.com/osprey-integrity/mosaic-json/aoi1_mosaic.json',
  'https://c-core-secure.s3.ca-central-1.amazonaws.com/osprey-integrity/mosaic-json/aoi2_mosaic.json',
  'https://c-core-secure.s3.ca-central-1.amazonaws.com/osprey-integrity/mosaic-json/aoi3_mosaic.json',
  'https://c-core-secure.s3.ca-central-1.amazonaws.com/osprey-integrity/mosaic-json/aoi4_mosaic.json'
]

export const defaultExtensions = [
  'png',
  'jpg',
]

export const pixelSelectionMethods = {
  first: 'first',
  highest: 'highest',
  lowest: 'lowest',
  mean: 'mean',
  median: 'median',
  stdev: 'stdev',
  bdix_stdev: 'bdix_stdev'
}

export const defaultPixelSelections = Object.values(pixelSelectionMethods)

export const resamplingMethods = {
  nearest: 'nearest',
  bilinear: 'bilinear',
  cubic: 'cubic',
  cubic_spline: 'cubic_spline',
  lanczos: 'lanczos',
  mode: 'mode',
  gauss: 'gauss',
  max: 'max',
  min: 'min',
  med: 'med',
  q1: 'q1',
  q3: 'q3'
}

export const defaultResamplingMethods = Object.values(resamplingMethods)

export const defaultIndexes = '1, 2, 3'

export const defaultIndexesArray = [defaultIndexes]

export const defaultParameters = {
  mosaicUrl: defaultMosaicUrl,
  extension: defaultExtension,
  scale: defaultScale,
  pixelSelection: defaultPixelSelection,
  resamplingMethod: defaultResamplingMethod,
  indexes: defaultIndexes,
  rescale: defaultRescale,
  tilerUrl: defaultTilerUrl,
  tileJsonUrl: defaultTileJsonUrl,
}

export const defaultAuth = !debug
  ? {}
  : {
  "id": {
    "sub": "5aaf0e59-f670-4dcb-8fde-6851a279d2cc",
    "cognito:groups": [
      "ccoreStaff"
    ],
    "email_verified": true,
    "cognito:preferred_role": "arn:aws:iam::844438014409:role/us-east-1_YT4Hv9PTG-ccoreStaffGroupRole",
    "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_YT4Hv9PTG",
    "cognito:username": "TestStaff",
    "cognito:roles": [
      "arn:aws:iam::844438014409:role/us-east-1_YT4Hv9PTG-ccoreStaffGroupRole"
    ],
    "aud": "6k3vdsah9095frbv7pos547aeh",
    "event_id": "d48cd90e-3b17-401d-bfce-8f3a949849e3",
    "token_use": "id",
    "auth_time": 1592909137,
    "exp": 1592912737,
    "iat": 1592909137,
    "email": "gerard.noseworthy@c-core.ca"
  },
  "access": {
    "sub": "5aaf0e59-f670-4dcb-8fde-6851a279d2cc",
    "cognito:groups": [
      "ccoreStaff"
    ],
    "event_id": "d48cd90e-3b17-401d-bfce-8f3a949849e3",
    "token_use": "access",
    "scope": "aws.cognito.signin.user.admin",
    "auth_time": 1592909137,
    "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_YT4Hv9PTG",
    "exp": 1592912737,
    "iat": 1592909137,
    "jti": "eb459f80-b071-481b-942c-1a0e6e06a1c9",
    "client_id": "6k3vdsah9095frbv7pos547aeh",
    "username": "TestStaff"
  },
  "id_token": "eyJraWQiOiJBV0ZIVXVyMjRhNEhMMFcxUHVYa3JQRjRcL3Naekw4OWc5U0Fra05vYjFZYz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI1YWFmMGU1OS1mNjcwLTRkY2ItOGZkZS02ODUxYTI3OWQyY2MiLCJjb2duaXRvOmdyb3VwcyI6WyJjY29yZVN0YWZmIl0sImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJjb2duaXRvOnByZWZlcnJlZF9yb2xlIjoiYXJuOmF3czppYW06Ojg0NDQzODAxNDQwOTpyb2xlXC91cy1lYXN0LTFfWVQ0SHY5UFRHLWNjb3JlU3RhZmZHcm91cFJvbGUiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9ZVDRIdjlQVEciLCJjb2duaXRvOnVzZXJuYW1lIjoiVGVzdFN0YWZmIiwiY29nbml0bzpyb2xlcyI6WyJhcm46YXdzOmlhbTo6ODQ0NDM4MDE0NDA5OnJvbGVcL3VzLWVhc3QtMV9ZVDRIdjlQVEctY2NvcmVTdGFmZkdyb3VwUm9sZSJdLCJhdWQiOiI2azN2ZHNhaDkwOTVmcmJ2N3BvczU0N2FlaCIsImV2ZW50X2lkIjoiZDQ4Y2Q5MGUtM2IxNy00MDFkLWJmY2UtOGYzYTk0OTg0OWUzIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE1OTI5MDkxMzcsImV4cCI6MTU5MjkxMjczNywiaWF0IjoxNTkyOTA5MTM3LCJlbWFpbCI6ImdlcmFyZC5ub3Nld29ydGh5QGMtY29yZS5jYSJ9.uEiOmbY6IyGOwM3Z8ZnqHJPwHwN35GvAOgTWUBMxsZAVmiTucHbtV-UpID8kkb42_4Pa7Ndc-Pva38F9ud-YfiY6HVStrQOs5ZfrB4fMYxI8NP34GgaJdz2PJEfJ11g4byc8KSW43VCXuQSzb_ZFfY3MN-yIr-Bw1BfKRw1a41wN8aynsPIqeLWf2rv5LQEVNunKrM5_Ddo_hFG-KLUSAgssLmvT8l02P3EeYgJTwk27nTmMSXTCKJD1X7DU5b26lV3ZAsrNEQpu5t4lsZrOoapYLKxq6rJKym5d_6mFcZq-P8FyZBq8-vi1Gr_5_CnQC-3JM2LkTw_bCHxjzU_wdw",
  "access_token": "eyJraWQiOiIrNHNpdE11NWs2dW9YTTRzb2pES2R6STVES2o2aFVwYUlMZzVYOXpydzRrPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI1YWFmMGU1OS1mNjcwLTRkY2ItOGZkZS02ODUxYTI3OWQyY2MiLCJjb2duaXRvOmdyb3VwcyI6WyJjY29yZVN0YWZmIl0sImV2ZW50X2lkIjoiZDQ4Y2Q5MGUtM2IxNy00MDFkLWJmY2UtOGYzYTk0OTg0OWUzIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTU5MjkwOTEzNywiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfWVQ0SHY5UFRHIiwiZXhwIjoxNTkyOTEyNzM3LCJpYXQiOjE1OTI5MDkxMzcsImp0aSI6ImViNDU5ZjgwLWIwNzEtNDgxYi05NDJjLTFhMGU2ZTA2YTFjOSIsImNsaWVudF9pZCI6IjZrM3Zkc2FoOTA5NWZyYnY3cG9zNTQ3YWVoIiwidXNlcm5hbWUiOiJUZXN0U3RhZmYifQ.ZfWiOd_1211ik278eCE6YNsQxYeyayQ04fjIB4Zuv6TedcbqYhHKjCpFeSlWmHSMdsFZ_OKvT8SYRpwcEhd_vHjXpjv4xHKUC2VkYUfSxlP42wlkSjcsjQqo5TBEgbp88ame6Vm6PZN2ccNn7unmx1HQ4BE-X4Db6EQhKaESs84087jdE1Fzco_eU2bXun49M_m9YAgR_ZkKPCNKK0Bg8f9-tB_aT2jdDhKPN5-MmhvHCpoCsQGlWU9y79gNqhF3PkAWsuppLCyNMrxczbY7_CG4hu5OK0R65Ygtwx60GU63hqV3ID2jdhlE_wNRrVWm_7lyIxOqSYzvfNtYnnTIEA",
  "refresh_token": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.Wzmng3r9dSz7dgwRmBTeNgTpq8rhPi3Q3omkpYR6q1z5VcXUGU3zL4ylw3Igtn8Brwxudss0e6FMWYWQzKgpjLRPIxrbxwc20aiebeIjaTaqv2A8RtVijPq6rlOUIF2hme-gaCX_xYUFMovs95-6dmkKxbnLARHy5t6espum0uly1NAzfC4IyRFfdoS84FrbNdPVbw_r5tRek_p8bpW0pBW7kXQyQNnW5ZJF0jQgTH4p5OnYPUMETn0ZNCaBEqrqqzVvh21AJrRZEvwJYxsgF9pumvXxBjV186imixfPkcEV2GLMVMjD6KjKIdTUjuLansOpINllWFlZ54GY8YZU4Q.q3Dzn95ilPN7-iC6.h7H3rYrwTr7PXQr7szt5_zAP7VAfV3vwXwAmFddoj4MXJ_8iJcBiKmdLzHrlw67blJKr8XDDl0IzAxbduKdrBLpVO4iCLcCMXvHFpBJk00pdym8aeuHbRHQMUc1OJlYT2DJs7CUs-4iXz3HRdqFu6L7vPMg0-8YVPbCMN_QrBO2yn6gm9_KcHCEtsCtxC8cCi1QSP_U6nQPdzCnwUskPTUEX44ovT5THYpjgF1ifG2uzwNxVU3EQ28xam_0NMkukDmdG2Z4oFEF-SOFMDwq0IDAQa2OZI4_6s3dX_BlpV8n1zlK56pVd9MmixtKvbV_blQztv-Mop-yTLFQzcViqe7c36Aewx9_VneZ4aUvArsNcUKX-YYBGBUw92hyv0mVodrx8KMgdX8xp4Tf789bvGfc5NGQBgeVvLcnKNjPBUl1HSfZ3lT1ScJH3XpMRANjHz_JsEazmS58cgZqpL2JXv6KOSJJwyQJ7Wckpbux59wA0hB_KE0LoOeih4epILea3TMc07U0B5mn3GulSdujLK5K-R9CMwelJ-v3tN0q5gqj2Uyfsx3KbiNP4DvxqzUTMQiDM23PbA2v8vRePdt0pLSfn_cvhbOZhLEQg05wcFeINWs3vPKGo0ySe_m84b7UguFYTY0ZZ6HmrgnVetFKxXyitJ0vySZkLy8MIiqKecp2jvcdcK-9GXt6UFBPpq31I0fSAwrr_X9lV8E2BB3b0s33EkDQ2LOhvwiipU0k2BHRkNRXmfwgtUUHOfsZGG1PT5D6rbigjAgozE8fO3vlVgn_qLUvAbN0uvTO9WP0jM2Q8-Ds5gbwWNR8RHqeZpM4pkHp4yStsPcT40JDjDYGXKMbHPpXB0GiEVxtPBblFFBGgNz3YCVymTtyUSgurtu5dIJiJednx2noguJ3YSvUbfd3B1O-TMREQWUIRhWh9vZubFZWj4rP9AVnpPUkHbPsDFAUOHujHxgubYCfs6tyXm7TCRNvDpooHgl8B3eEXcJy_iMo7oYZN6CW_dkICDi6-6iaMH3qBob010N9uFj8y5o6v6-Q2SZfQkM-r1nITpZf0eViSQ4uP6gmUyPHunJIuIC7kxivU-LwpBGDmYp0Plje6F8olUbDjirzDde7MVam4AbiiMLthy1Kn0d0KuhQjs9L6LWLbURXQlNUnW3Lu3_cFMoZTkMOF9OmJ5u0j1yoIJ6UFsm0P2frkPBCNkcXrTqxoYCqGESiYYF2sv4UsYjGjE2ZP0QylpPiKvJiL72_2SCYuJOzea8exlag3spHADuCPg2Mb-w._wiM3hZHwzHb040kp_8wpQ",
  "credentials": {
    "identity_id": "us-east-1:05a48196-f00f-43cc-a339-9c3843d9179c",
    "access_key_id": "ASIA4JHDNRHEWTAVU3JW",
    "secret_key": "WZBvJ1LxkrJdYXGTUbUxsr4qpCXgnbd/5fUF6EhW",
    "session_token": "IQoJb3JpZ2luX2VjEOv//////////wEaCXVzLWVhc3QtMSJHMEUCIQDaZx618Md4l3kW54VX5kN4kZgJrlKUj/remV15W75zZAIgfB1mQ9sQzm7YCiY7m8tQO2xeW95YsaiU/fvsd8NZbqsq/gMIdBACGgw4NDQ0MzgwMTQ0MDkiDPc53tMRhX3BJpRD2CrbA3s/kRoQbwOvvw5Ntld9HbKHHCZUKiRuMO4Ke7cXZjgLvKqqDnJE07fw1cc2Y37+dip1AD/hO8zuYOJPuNK8XCavpS4Hwmu+ghyl2cmP0j70kikKjonxfx/C07OqcfsoICmZ9T0yCsR1Ecaqe1IaMrQfJ/sNkJRbPg8UOxD9ckeG5zkQqVN0ky5dU51o6eiHjRd0GGMxJGPX7FRHi3OX2hYPj96reV0EIZgR4XZIAHCa+SXERbUgNyRfJpXMv3ESDojPzOZuexCcKGDkJgKltnFC4XQtORcofnOG1hhVrUzQ9MeT3vTV9JSyTF/MiUxquSYzvZJNnB/g5hzz94DHDM7N6qUDX4Zya2q61uZ05Dd6/4k2qTumExKTuQnm5lzUyCc/N2SCvyXGGgOanlnCEw5Y4RXDc0XAvOjG6CO9XsabhXqQL6/OIaaaW9ogC5j6efr+I9a3ESMU26+EgkwpprwYomHEVI1K2N3lQDI8XjkVrMwnuJbWl9ZGSiFJ9WO5S5vuwz7m0jLUixozDi+ZCwdb/AbYygp2WETeuuvBUC9R+Pyr38kI3xfV3332EIPeToxboR8aAr2DZAk/7zvtD5RGiAAmMyMKN+IqcuPu/dZGxwAWdwZOmuZtGHQw0rrH9wU6ywLrk4Q0HEQlvrN6h/JsKmvdgMRzf/RweWPW4d5SSgkbUTzVmuceZMp1XUHGAUzYIatVmb0UcFK8ubN7BPpPvW2j6ljALiXTsTIi4FhrclChqrm9YnB73R5nyETtuS+BMz+v0vGY/XizcN3RTHkaPodqkdU4Bkh6ld5Q2v6WJABrC/7G+P1ID3yA+eelg2ISdLM65crgzy2Qr7Q5MzvFJ0AlIaqit823EuLjardD9QFCKhYPiNhyktiTnGJpRmsurPCrPR69H64yllmrTiyXLBoRnZtchaNJvhHwSuoJY1jwcLLcYtAsAYNgOYVjpBdSnBxG1aO18maTzxKCiNcnc8g1+btS9xQ1h0yTfG+Qn2BWjqfFVK/kR18W72domNJwj1k+g/8EyTxYVPA8UYNBwvRY6Q9YBittDI7Ip5lwEvTI048KomGuorn2lnDA"
  }
}
