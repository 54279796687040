// @flow

import React, { type Node } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'

import NotAuthenticatedNavigationContainer from '../navigation/NotAuthenticatedNavigationContainer'
import AuthenticatedNavigationContainer from '../navigation/AuthenticatedNavigationContainer'
import LoadingRoute from '../routes/LoadingRoute'
import { getAuth } from '../redux/authSelector'

function NavigationContainer (): Node {
  const auth = useSelector(state => getAuth(state))

  return (
    <Router>
      <Routes>
        {!auth ? (
          <Route
            path='/'
            exact
            component={LoadingRoute}
            element={<LoadingRoute />}
          />
        ) : auth && !auth.credentials ? (
          <NotAuthenticatedNavigationContainer />
        ) : (
          <AuthenticatedNavigationContainer />
        )}
      </Routes>
    </Router>
  )
}

export default NavigationContainer
