// @flow

import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  themeName: 'Polar Code',
  // palette from https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=0277BD&secondary.color=BDBDBD
  palette: {
    primary: {
      light: '#58a5f0',
      main: '#303f9f',
      dark: '#004c8c'
    },
    secondary: {
      light: '#efefef',
      main: '#bdbdbd',
      dark: '#8d8d8d'
    },
  },
})

window.theme = theme

export default theme
