// @flow

import React, { type Node } from 'react'
import { Route, Routes } from 'react-router-dom'
import loadable from '@loadable/component'

const MapRoute = loadable(() => import('../routes/MapRoute'))

function AuthenticatedNavigationContainer (): Node {
  return (
    <Routes>
      <Route path='/' element={<MapRoute />} />
    </Routes>
  )
}

export default AuthenticatedNavigationContainer
