// @flow

import React, { type Node } from 'react'
import * as Sentry from '@sentry/browser'

import Provider from './Provider'
import App from './App'
import store from './redux/store'
import theme from './theme'

Sentry.init({dsn: "https://7306e5b0a30b4a968a8272f87c8e7976@sentry.io/1810152"})

// if (process.env.NODE_ENV !== "production") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render")

//   whyDidYouRender(React, {
//     collapseGroups: true,
//     // include: [/.*/],
//     include: [/^TextEditContainer/],
//     exclude: [/^Link/, /^Route/, /^BrowserRouter/, /^Draw/],
//   });
// }

function Root(): Node {
  return (
    <Provider store={store} theme={theme}>
      <App />
    </Provider>
  )
}

export default Root
