// @flow

import React, { type Node } from 'react'
import { Route, Routes } from 'react-router-dom'
import loadable from '@loadable/component'

const SignInRoute = loadable(() => import('../routes/SignInRoute'))
const SignUpRoute = loadable(() => import('../routes/SignInRoute'))
const ResetPasswordRoute = loadable(() => import('../routes/SignInRoute'))

function NotAuthenticatedNavigationContainer (): Node {
  return (
    <Routes>
      <Route path='/' element={<SignInRoute />} />
      <Route path='/signup' element={<SignUpRoute />} />
      <Route path='/resetpassword' element={<ResetPasswordRoute />} />
    </Routes>
  )
}

export default NotAuthenticatedNavigationContainer
